/**
 * Colour Palette
 *
 * Primary: #c62828 - White
 * Light: #ff5f52 - Black
 * Dark: #8e0000 - White
 */

/**
// Breakpoints
$grid-breakpoints: (
    xs: 0, // All devices
    sm: 320px, // iPhone 5s and lower Landscape
    md: 768px, // iPad Portrait
    lg: 992px, // iPad Landscape
    xl: 1200px // Large screens
) !default;
 */

.App {
    width: 100%;
}

.App-header {
    background-color: #c62828;
    color: #fff;
    padding: 20px;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.App-header h1 {
    margin: 0;
}

.App-header a {
    display: block;
    height: auto;
    color: white;
}

.App-header .butter-cms img {
    max-height: 40px;
    margin: 10px 0 0 0;
}

.App-articles nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.pagination a,
.App-articles nav ul li a {
    display: block;
    padding: 20px;
    background-color: white;
    color: #c62828;
}

.pagination a:hover,
.App-articles nav ul li a:hover {
    display: block;
    padding: 20px;
    background-color: #c62828;
    color: white;
}

.App-footer {
    background-color: #c62828;
    color: #fff;
    padding: 20px;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}
