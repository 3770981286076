.Article-primary-content {
    margin: 10px;
}

.Article-primary-content img {
    width: 100%;
}

.Article-featured-image {
    margin: 40px 0 0 0;
}

.Article-category-pill {
    border-radius: 100px;
    padding: 10px 20px;
    background-color: #ff5f52;
    color: black;
    margin: 0 20px 20px 0;
}

.Article-author img {
    max-width: 100px;
}

@media screen and (min-width: 768px) {
    .Article-primary-content {
        width: 800px;
        margin: 50px auto;
    }
}
